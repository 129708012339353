import { HttpErrorResponse } from "@angular/common/http";
import {
    AfterViewInit,
    Component,
    OnInit,
    SecurityContext,
    ViewChild,
} from "@angular/core";
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import {
    SkyKickModalService,
    SkyKickProductPage,
    TaskManagerService,
} from "@skykick/core";
import { AbstractUserProvider } from "@skykick/platform-identity-auth-auth0-angular";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import { BannerSessionService } from "../services/banner-session.service";
import { BrandSettingsService } from "../settings/settings.service";
import { CustomUrlComponent } from "../shared/custom-url/custom-url.component";
import { CustomerSupportComponent } from "../shared/customer-support/customer-support.component";
import { CloudBackupFormModel } from "./cloud-backup-form.model";
import { CloudBackupPreviewDialogComponent } from "./cloud-backup-preview-dialog/cloud-backup-preview-dialog.component";
import { CloudBackupService } from "./cloud-backup.service";

@Component({
    // tslint:disable-next-line: component-selector
    selector: "main[sk-dashboard]",
    templateUrl: "./cloud-backup.component.html",
    styleUrls: ["./cloud-backup.component.scss"],
    providers: [CloudBackupService, SkyKickModalService],
})
export class CloudBackupComponent
    extends SkyKickProductPage
    implements OnInit, AfterViewInit {
    /**
     * The form parent form for the component and all subcomponents. Initialized within ngOnInit.
     */
    public skCloudBackupForm!: UntypedFormGroup;
    /**
     * Returns true if the user has never saved whitelabel cloudbackup data to the server.
     */
    public skFirstSave: boolean;
    public skPublished: boolean;
    public isLoading: boolean;
    public isInformationFeedbackVisible: boolean = true;

    public get skPageTitle(): string | null {
        const skPageTitle = this.skCloudBackupForm.get(
            "skPageTitle"
        ) as UntypedFormControl | null;
        if (!skPageTitle) {
            return null;
        }
        return skPageTitle.value as string;
    }
    /**
     * The reference to the child component, CustomUrlComponent. Initialized by Angular
     */
    @ViewChild(CustomUrlComponent) public skCustomUrl!: CustomUrlComponent;
    /**
     * The reference to the child component, CustomUrlComponent. Initialized by Angular
     */
    @ViewChild(CustomerSupportComponent)
    public skCustomerSupport!: CustomerSupportComponent;

    constructor(
        taskManagerService: TaskManagerService,
        readonly userService: AbstractUserProvider,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly cloudBackupService: CloudBackupService,
        private readonly brandSettingsService: BrandSettingsService,
        private readonly toastrService: ToastrService,
        private readonly modalService: SkyKickModalService,
        private readonly translateService: TranslateService,
        private readonly sanitizer: DomSanitizer,
        private readonly bannerSessionService: BannerSessionService
    ) {
        super(taskManagerService);
        this.isLoading = true;
        this.skFirstSave = true;
        this.skPublished = false;
    }

    ngOnInit(): void {
        // Check if sk-feedback visible for user current session
        this.bannerSessionService.removeBackupLandingBannerKeyOnNewSession();
        this.isInformationFeedbackVisible =
            this.bannerSessionService.checkIfBackupLandingFeedbackWasClosedForSession();

        // Construct the form.
        this.skCloudBackupForm = this.formBuilder.group({
            skPageTitle: ["", [Validators.required]],
            skCustomUrl: this.formBuilder.group({
                skCustomUrlSelection: this.formBuilder.control("subdomain", [
                    Validators.required,
                ]),
                skSubDomainName: this.formBuilder.control(""),
                skDomainName: this.formBuilder.control(""),
                skCertificate: this.formBuilder.control(""),
                skSelectedSslCert: this.formBuilder.control(""),
                skSslPassphrase: this.formBuilder.control(""),
            }),
            skCustomerSupport: this.formBuilder.group({
                skSupportOptions: this.formBuilder.control("support-details", [
                    Validators.required,
                ]),
                // by default we require support-details
                skSupportEmail: this.formBuilder.control("", [
                    Validators.required,
                ]),
                skSupportPhone: this.formBuilder.control("", [
                    Validators.required,
                ]),
                skSupportHours: this.formBuilder.control("", [
                    Validators.required,
                ]),
                skSupportUrl: this.formBuilder.control(""),
            }),
            skLearnMoreUrl: this.formBuilder.control("", [
                Validators.pattern(
                    /^https?:\/\/[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}\/?/
                ),
            ]),
        });
    }

    handleCloseFeedback() {
        this.bannerSessionService.closeBackupLandingBannerForCurrentSession();
        this.isInformationFeedbackVisible = false;
    }

    async ngAfterViewInit(): Promise<void> {
        // Initialize Child Form Groups with data if available.
        try {
            this.isLoading = true;
            const backupSettings =
                await this.cloudBackupService.getBackupWhiteLabelSettings();
            if (!backupSettings) {
                this.skFirstSave = true;
                this.skPublished = false;
                return;
            }

            this.skFirstSave = false;
            this.skPublished = backupSettings.skIsEnabled || false;

            this.skCloudBackupForm.patchValue(backupSettings);
            (
                this.skCloudBackupForm.get(
                    "skCustomUrl.skCustomUrlSelection"
                ) as UntypedFormControl
            ).updateValueAndValidity();
        } catch (err) {
            console.error(err);
        } finally {
            this.isLoading = false;
        }
    }

    async onSubmit(publish: boolean): Promise<void> {
        this.validateRequirements();
        if (this.skCloudBackupForm.invalid) {
            return;
        }

        const cloudBackupFormData: CloudBackupFormModel =
            this.skCloudBackupForm.getRawValue();
        try {
            this.isLoading = true;
            await this.cloudBackupService.saveBackupWhiteLabelSettings(
                cloudBackupFormData,
                publish
            );
            this.skPublished = publish;
            this.skFirstSave = false;

            const successTitle = await lastValueFrom(
                this.translateService.get("COMMON.SUCCESS")
            );
            const successMessage = await lastValueFrom(
                this.translateService.get("COMMON.SAVED_SETTINGS")
            );
            this.toastrService.success(successMessage, successTitle);
        } catch (ex) {
            const failureTitle = await lastValueFrom(
                this.translateService.get("COMMON.FAILURE")
            );
            const failureMessage = await lastValueFrom(
                this.translateService.get("ERRORS.ERROR_SAVED_SETTINGS")
            );
            let toastMessage: string = failureMessage;

            // Check if the exception is an HttpErrorResponse.
            if (!(ex instanceof HttpErrorResponse)) {
                this.toastrService.error(toastMessage, failureTitle);
                return;
            }

            // Check to see if the exception has an error property and that the error property is a string.
            const hasErrorMessage: boolean =
                !!ex.error && typeof ex.error === "string";
            toastMessage = hasErrorMessage
                ? (ex.error as string)
                : toastMessage;

            this.toastrService.error(toastMessage, failureTitle);
        } finally {
            this.isLoading = false;
        }
    }

    async onPreview(): Promise<void> {
        try {
            this.isLoading = true;
            this.validateRequirements();
            const cloudBackupFormData: CloudBackupFormModel =
                this.skCloudBackupForm.getRawValue();
            const brandSettings =
                await this.brandSettingsService.getBrandSettings();
            this.isLoading = false;

            // Constructs then opens the modal view.
            const skykickModal = this.modalService.open<
                CloudBackupPreviewDialogComponent,
                boolean
            >(CloudBackupPreviewDialogComponent, {
                size: "xl",
            });

            // Note: NgbModalOptions doesn't let us pass init data, therefore we have to pass data using the componentInstance reference.
            const cloudBackPreviewDialog: CloudBackupPreviewDialogComponent =
                skykickModal.componentInstance;
            const customerSupportInfo = cloudBackupFormData.skCustomerSupport;
            if (brandSettings) {
                cloudBackPreviewDialog.skCompanyName =
                    brandSettings.companyName;
                cloudBackPreviewDialog.skLogoUrl = brandSettings.companyLogoUri;
            }
            cloudBackPreviewDialog.skPublished = this.skPublished;
            cloudBackPreviewDialog.skSupportOption =
                customerSupportInfo.skSupportOptions;
            if (customerSupportInfo.skSupportOptions === "support-details") {
                cloudBackPreviewDialog.skCustomerSupportInfo = {
                    emailAddress: customerSupportInfo.skSupportEmail,
                    hours: customerSupportInfo.skSupportHours,
                    phoneNumber: customerSupportInfo.skSupportPhone,
                };
            } else {
                cloudBackPreviewDialog.skCustomerSupportInfo =
                    customerSupportInfo.skSupportUrl;
            }

            // Publish changes if the user clicked the publish button.
            await skykickModal.result.then(async (result) => {
                const publish = result.data;
                return publish ? this.onSubmit(true) : Promise.resolve();
            });
        } finally {
            this.isLoading = false;
        }
    }

    private validateRequirements(): void {
        // Force the control and all child controls to recheck themselves for validity.
        (
            this.skCloudBackupForm.get(
                "skCustomUrl.skCustomUrlSelection"
            ) as UntypedFormControl
        ).updateValueAndValidity();
        const skPageTitle = this.skCloudBackupForm.get(
            "skPageTitle"
        ) as UntypedFormControl | null;
        const skLearnMoreUrl = this.skCloudBackupForm.get(
            "skLearnMoreUrl"
        ) as UntypedFormControl | null;
        if (skPageTitle) {
            skPageTitle.markAsDirty();
        }
        if (skLearnMoreUrl) {
            const sanitizedUrl =
                this.sanitizer.sanitize(
                    SecurityContext.URL,
                    skLearnMoreUrl.value
                ) ?? "";
            skLearnMoreUrl.setValue(sanitizedUrl);
            skLearnMoreUrl.updateValueAndValidity();
        }
        this.skCustomUrl.validateRequirements();
        this.skCustomerSupport.validateRequirements();
    }
}
